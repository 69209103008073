export default {
  methods: {
    $errorHandler(error) {
      console.error('[ERROR]:', error)

      this.$catchupErrorMessage(error)
    },
    $catchupErrorMessage(error, title) {
      if (error && error.response && error.response.data) {
        const { message } = error.response.data

        this.$swal({
          icon: 'error',
          title: message,
          text: title,
          confirmButtonText: 'ตกลง',
        })

        return true
      }

      this.$swal({
        icon: 'error',
        text: title,
        confirmButtonText: 'ตกลง',
      })

      return false
    },
    $catchupValidationError(error, mapper = {}) {
      if (!error || !error.response || !error.response.data || error.response.data.type !== 'VALIDATION_ERROR') {
        return false
      }

      const statusCode = error && error.response ? error.response.status : '000'

      // Catch up validation by message
      if (!error.response.data.data) {
        if (error.response.data.message) {
          this.$swal({
            icon: 'error',
            title: error.response.data.message,
            text: `${this.$t('notify.form_error')} (${statusCode})`,
            confirmButtonText: this.$t('notify.ok'),
          })

          return true
        }

        return false
      }

      // Catch up validation by field
      //
      this.$swal({
        icon: 'error',
        title: `${this.$t('notify.form_error')} (${statusCode})`,
        text: this.$t('notify.validation_message'),
      })

      const { data } = error.response.data
      let fieldData

      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (fieldData of data) {
          const { field } = fieldData
          // eslint-disable-next-line no-prototype-builtins
          if (mapper.hasOwnProperty(field)) {
            const messages = []
            messages.push(fieldData.message)
            mapper[field](messages)
          }
        }
      }

      return true
    },
  },
}
